import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>MECOIN.CA</h1>
        <p>Metalúrgica, Construcción e Instalaciones C.A</p>
      </header>
    </div>
  );
}

export default App;
